@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

a, button {
    transition: all 0.5s;
}

:root {
    --green_clr: #1E8067;
    --yellow_clr: #F9DE15;
    --orange_clr: #F79001;

    --ff: 'Lato', sans-serif;
}

html, body, div, span, applet, object, iframe,
p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html, body {
    font-family: var(--ff);
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    overflow-x: hidden;
}

.desktop_only,
.tab_only,
.mob_only,
.tab_desktop_only,
.mob_tab_only {
    display: none;
}


@media (min-width: 1280px) {
    .desktop_only {
        display: inline-block;
    }
}

@media (max-width: 1279px) {

    .mob_tab_only {
        display: inline-block;
    }
}

@media (min-width: 768px) {
    .tab_desktop_only {
        display: inline-block;
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .tab_only {
        display: inline-block;
    }
}

@media (max-width: 767px) {
    .mob_only {
        display: inline-block;
    }
}

@media (min-width: 1200px) {

    @media (min-resolution: 97dpi) {
        body {
            zoom: 0.87;
        }
    }
}

@media not all and (min-resolution: .001dpcm) {
    /* @media {

      body{zoom:1;}

    } */
}

.container_f_wrap {
    max-width: 100%;
    width: 100%;
    padding: 0 20px;
}

.container_wrap {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.container_inner {
    display: flex;
    justify-content: space-between;
}

.df {
    display: flex;
}

.dif {
    display: inline-flex;
}


.aic {
    align-items: center;
    cursor: pointer;
}

.aifs {
    align-items: flex-start;
}

.aife {
    align-items: flex-end;
}

.jcfs {
    justify-content: flex-start;
}

.jcc {
    justify-content: center;
}

.jcfe {
    justify-content: flex-end;
}

.jcsb {
    justify-content: space-between;
}

.jcsa {
    justify-content: space-around;
}

.fdc {
    flex-direction: column;
}

.fdcr {
    flex-direction: column-reverse;
}

.fdr {
    flex-direction: row;
}

.fdrr {
    flex-direction: row-reverse;
}

.fww {
    flex-wrap: wrap;
}

.fww > div {
    margin-bottom: 26px;
}

.fwnw {
    flex-wrap: nowrap;
}


.ta_left {
    text-align: left !important;
}

.ta_center {
    text-align: center !important;
}

.ta_right {
    text-align: right !important;
}

.sec_padding {
    padding: 75px 0;
}

.sec_title {
    text-align: center;
    margin-bottom: 45px;
}

.sec_title > h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #000;
    margin-bottom: 8px;
}

.c_33p {
    width: 33.33%;
    padding: 0 13px;
}

.c_25p {
    width: 25%;
    padding: 0 13px;
}

.c_50p {
    width: 50%;
    padding: 0 13px;
}

.c_grid_m {
    margin: 0 -13px;
}

.nbs {
    box-shadow: none !important;
}

b, strong {
    font-weight: 700 !important;
}

.web_btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
    column-gap: 15px;
    background: var(--green_clr);
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
}

.web_link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    color: var(--green_clr);
    text-decoration: none;
}

.web_link:hover,
.web_btn:hover {
    opacity: 0.8;
}


@media (max-width: 767px) {
    .c_33p,
    .c_25p,
    .c_50p {
        width: 100%;
    }
}

#web_header {
    background-image: url("./../assets/images/header_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
    background-color: var(--green_clr);
    min-height: 800px;
}

#web_header > nav {
    padding: 30px 0;
}

.web_header_btns > ul {
    column-gap: 9px;
}

.web_header_btns > ul li a {
    padding: 9px 24px;
    border-radius: 5px;
    border: 2px solid;
    line-height: 19px;
    display: block;
}

.web_header_btns > ul li a:hover {
    opacity: 0.8;
}

.web_header_btns > ul li:nth-child(1) a {
    border-color: rgba(255, 255, 255, 0.6);
    color: #fff;
}

.web_header_btns > ul li:nth-child(2) a {
    border-color: #fff;
    background: #fff;
    color: var(--green_clr);
}

.web_header_btns > ul li:nth-child(3) a {
    border-color: var(--yellow_clr);
    background: var(--yellow_clr);
    color: #000;
}


.banner_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.banner_text > h3 {
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 19px;
    color: var(--yellow_clr);
}

.banner_text > h1,
.banner_text > h2 {
    font-weight: 900;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 49px;
    color: #fff;
}

.banner_phone_img {
    width: 50%;
}

.banner_phone_img img {
    display: block;
}

.app_ratings {
    color: #fff;
    margin-bottom: 65px;
}

.app_ratings > h4 {
    font-weight: 700;
    font-size: 24px;
}

.app_stars {
    font-size: 26px;
    color: var(--yellow_clr);
    margin: 0 15px;
}

.app_ratings > span {
    font-weight: 600;
    opacity: 0.8;
}

.app_btns {
    column-gap: 9px;
}

.app_btns li a {
    padding: 20px 50px;
    border-radius: 10px;
    display: block;
    font-weight: 700;
    font-size: 22px;
    text-decoration: none;
}

.app_btns li a i {
    padding-right: 15px;
    font-size: 31px;
}

.app_btns li a:hover {
    opacity: 0.8;
}

.app_btns li a span {
    transform: translateY(-3px);
    display: inline-block;

}

.app_btns li:first-child a {
    color: #000;
    background: var(--yellow_clr);
}

.app_btns li:last-child a {
    color: var(--green_clr);
    background: #fff;
}

.person_name_proffesion {
    margin: 16px 0 19px;
}

.person_quote {
    margin: 0 auto;
    max-width: 990px;
    font-style: italic;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}

.p_img {
    width: 96px;
    height: 98px;
    position: relative;
    background-image: url("./../assets//images/person_img_frame.png");
    background-size: 95px 98px;
    background-position: center;
    background-repeat: no-repeat;
}

.p_img > img {
    transform: translate(9px, 8px);
    background: #EFEFEF;
    width: 77px !important;
    height: 77px;
    border-radius: 100px;
    object-fit: cover;
    object-position: center top;
}

.client_testimonial_slider .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
    column-gap: 10px;
}

.client_testimonial_slider .owl-dots button {
    width: 12px;
    height: 12px;
    border: 1px solid #CBCBCB !important;
    border-radius: 50px;
    transition: all 0.5s;
}

.client_testimonial_slider .owl-dots button.active {
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    background: var(--green_clr);
    box-shadow: var(--green_clr) 0 0 0 2px;
}


#app_advantages {

    background: #f3f3f3; /* Old browsers */
    background: -moz-linear-gradient(top, #f3f3f3 0%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f3f3f3 0%, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f3f3f3 0%, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

#app_advantages > .container_wrap {
    background-image: url("./../assets/images/advantages_sec_graphic.png");
    background-position: right top;
    background-repeat: no-repeat;
}

.app_info_box {

    position: relative;
    height: 100%;
}

.app_info_box_inner {
    position: relative;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0px 26px 49px rgba(146, 165, 184, 0.14);
    border-radius: 14px;
    padding: 50px;
    height: 100%;
}

.app_info_box:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 14px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 1;
    background: #f7f7f7; /* Old browsers */
    background: -moz-linear-gradient(top, #f7f7f7 0%, #e6e6e6 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f7f7f7 0%, #e6e6e6 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f7f7f7 0%, #e6e6e6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.app_info_box_inner > h4 {
    margin-bottom: 38px;
}

.app_info_box_inner > h4 > img {
    margin-right: 12px;
}

.members_div {
    column-gap: 17px;
}

.members_div ul li {
    width: 26px;
    height: 26px;
    border-radius: 80px;
    border: 1px solid #FFFFFF;
    box-shadow: -5px 4px 14px rgba(151, 151, 151, 0.28);
    overflow: hidden;
}

.members_div > h5 {
    font-size: 16px;
    line-height: 18px;
    color: #000;
    font-weight: 400;
}

.members_div > h5 span {
    color: var(--green_clr);
    font-weight: 600;
}

.all_user_ul li:not(:first-child) {
    margin-left: -6px;
}

.members_div ul li img {
    width: 26px;
    height: 26px;
    object-fit: cover;
    object-position: center top;
}

.rb_content {
    margin: 45px 0;
    font-weight: 400;
}

.appbox_last {
    padding-left: 30px;
}

.appbox_last > h3 {
    font-weight: 900;
    font-size: 30px;
    line-height: 48px;
    color: #000;
    margin-bottom: 17px;
}

.appbox_last > ul {
    margin-bottom: 35px;
}

.appbox_last > ul li {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    padding-left: 37px;
    margin-bottom: 17px;
    display: block;
}

.appbox_last > ul li:before {
    content: '\f269';
    font-family: 'Material-Design-Iconic-Font';
    color: var(--orange_clr);
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
}

#image_gallery {
    background: var(--green_clr);
}

#image_gallery .sec_title {
    color: #fff;
    text-align: left;
}

.sti_left h2 {
    padding-right: 23px;
    margin-right: 23px;
}

.sti_left h2 {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.gallery_slider_nav {
    column-gap: 20px;
}

.gallery_slider_nav li button {
    width: 50px;
    height: 50px;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.04);
    border-radius: 70px;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #022269;
    border: none;
    cursor: pointer;
    opacity: 0.5;
}

.gallery_slider_nav li button:hover {
    opacity: 1;
}

.gallery_slider_trigger {
    margin-bottom: 45px;
}

.phon_s_shot {
    border-radius: 28px;
    background: rgb(255 255 255 / 18%);
    padding: 14px;
}

.phon_s_shot > img {
    border-radius: 18px;
}

.gallery_slider_trigger .owl-dots {
    display: none !important;
}

.tabs_content {
    margin-top: 83px;
}

.tab_box.ad_box {
    margin-bottom: 40px;
}

.tab_box_inner {
    background: #FAFAFA;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 192px;
}

.tab_box_inner img {
    max-width: 100%;
    display: block;
    border-radius: 10px 10px 0 0;
}

.box_no {
    width: 42px;
    height: 42px;
    background: var(--yellow_clr);
    border-radius: 70px;
    font-weight: 700;
    font-size: 20px;
    color: #000;
    display: block;
    line-height: 42px;
    text-align: center;

}

.ad_box .box_no {
    position: absolute;
    top: -18px;
    left: -18px;
}

.ad_box .tab_box_inner {
    margin-bottom: 17px;
}

.tab_box h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
}

.tab_box p {
    font-size: 13px;
    line-height: 18px;
}

.app_tabs li a {
    background: #EFEFEF !important;
    color: var(--green_clr) !important;
    position: relative;
}

.app_tabs li a.active_tab_trigger,
.app_tabs li a:hover {
    background: var(--green_clr) !important;
    color: #fff !important;
    opacity: 1 !important;
}

.app_tabs li a.active_tab_trigger:after {
    content: '\f2f2';
    font-family: 'Material-Design-Iconic-Font';
    color: var(--green_clr);
    position: absolute;
    bottom: -20px;
    left: calc(50% - 12.5px);
    font-size: 60px;
}

.ap_box .box_no {
    position: relative;
    left: -2vw;
    top: -3vw;
}

.ap_box .tab_box_inner {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.ap_box .tab_box_inner h3 {
    margin-top: 20px;
}

.ap_box,
.ap_box .tab_box_inner {
    height: 100%;
}

.ap_box .tab_box_inner {
    height: 75vw;
}

.single_tab:not(.active_tab) {
    display: none
}

#web_footer {
    background: #000;
}


.add_b_c {
    transform: translateY(-45px);
    padding-bottom: 25px;
}

.add_banner {
    background: var(--green_clr);
    border-radius: 10px;
    overflow: hidden;
}

.add_content {
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
}

.add_content h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 4px;
}

.add_content p {
    font-size: 13px;
    line-height: 18px;
}

.add_content * {
    padding-left: 20px;
}


.add_buttons_img {
    background-image: url("./../assets/images/add_banner_img.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
}

.add_buttons_img ul {
    column-gap: 7px;
}

.add_buttons_img ul li {
    padding-top: 13px;
}

.social_contact_bar .scb:nth-child(2) {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 40px;
    margin-right: 40px;
    border-left: 1px solid rgba(255, 255, 255, 0.16);
    border-right: 1px solid rgba(255, 255, 255, 0.16);
}

.social_contact_bar .scb:nth-child(3) {
    column-gap: 28px;
}

.footer_content {
    margin: 45px auto;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
    opacity: 0.7;
    max-width: 1118px;
}

.footer_p_logos {
    column-gap: 50px;
}

.footer_bottom {
    margin-top: 54px;
    padding: 50px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer_bottom p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
    opacity: 0.7;
}

.footer_bottom p a {
    color: #FFFFFF;
    opacity: 0.7;
    text-decoration: none;
}

.sep_span {
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 1279px) {
    #web_header nav {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        background: var(--green_clr);
    }
}

@media (min-width: 1024px) and (max-width: 1180px) {
    .app_ratings > h4 {
        font-size: 18px;
    }

    .app_ratings > span {
        font-size: 14px;
    }

}

@media (max-width: 1023px) {

    #web_header {
        min-height: inherit;
    }

    .banner_phone_img {
        display: none;
    }

    .banner_text {
        width: 100% !important;
        align-items: center !important;
        padding: 45px 0;
    }

    .banner_text * {
        text-align: center;
    }

    .social_contact_bar {
        flex-wrap: wrap;
    }

    .social_contact_bar .scb {
        width: auto !important;
    }

    .social_contact_bar .scb:nth-child(2) {
        padding-right: 0px;
        margin-right: 0px;
        border-right: 0;
    }

    .social_contact_bar .scb:nth-child(3) {
        margin-top: 35px;
    }

    .footer_content span {
        display: block;
        margin-top: 20px;
    }

    .footer_bottom p span:first-child {
        display: block;
        font-size: 0;
    }

    .footer_p_logos {
        column-gap: 47px;
        flex-wrap: wrap;
        row-gap: 20px;
        max-width: 460px;
        margin: 0 auto;
    }

}

@media (max-width: 767px) {
    .ap_box .tab_box_inner {
        height: 200vw;
    }

    .ap_box .box_no {
        position: relative;

        left: -7vw;
        top: -7vw;

    }

    html, body {
        font-size: 14px;
        line-height: 22px;

    }

    .web_logo,
    .web_logo img {
        max-width: 165px;
    }

    .web_logo a {
        display: block;
        transform: translateY(10px);
    }

    .web_header_btns > ul li a {
        padding: 8px 15px;
    }

    .web_header_btns > ul li a i {
        font-size: 20px;
    }

    .banner_text {
        padding: 30px 0 42px;
    }

    .banner_text > h3 {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
    }

    .banner_text > h1, .banner_text > h2 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 12px;
    }

    .app_ratings {
        flex-wrap: wrap;
    }

    .app_ratings h4,
    .app_ratings span {
        width: 50%;
        order: 1;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 6px;
    }

    .app_stars {
        width: 100%;
        order: 2;
    }

    .app_btns li a {
        padding: 14px 37px;
        border-radius: 5px;
        font-size: 16px;
        line-height: 19px;
    }

    .app_btns li a i {
        font-size: 24px;
    }

    .app_ratings {
        color: #fff;
        margin-bottom: 30px;
    }

    .sec_padding {
        padding: 32px 0;
    }

    .sec_title {
        margin-bottom: 27px;
    }

    .sec_title > h2 {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .person_quote {
        font-size: 16px;
        line-height: 25px;
    }

    #app_advantages {
        padding: 0px;
    }

    #app_advantages > .container_wrap {
        padding: 30px 20px;
        background-position: right top -8px;
        background-size: 150px;
    }

    .c_grid_m {
        flex-direction: column;
    }

    .app_info_box {
        margin-bottom: 25px
    }

    .app_info_box_inner {
        position: relative;
        z-index: 2;
        background: #FFFFFF;
        box-shadow: 0px 26px 49px rgba(146, 165, 184, 0.14);
        border-radius: 14px;
        padding: 26px 20px;

    }

    .app_info_box_inner > h4 {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .rb_content {
        margin: 16px 0;
    }

    .appbox_last > h3 {
        font-size: 22px;
        line-height: 26px;
    }

    .appbox_last > ul {
        margin-bottom: 22px;
    }

    .appbox_last > ul li {
        padding-left: 28px;
    }

    .appbox_last {
        padding-left: 18px;
    }

    .web_btn {
        display: flex;
    }

    .sec_title_inner .sti_left {
        align-items: flex-start;
        flex-direction: column;
    }

    .sti_left h2 {
        border-right: 0;
        margin: 0;
        padding: 0;
        margin-bottom: 6px;
    }

    .gallery_slider_nav {
        column-gap: 10px;
    }

    .gallery_slider_nav li button {
        width: 38px;
        height: 38px;
        font-size: 19px;
    }

    .phon_s_shot {
        border-radius: 14px;
        padding: 7px;
    }

    .phon_s_shot > img {
        border-radius: 9px;
    }

    .app_tabs li a.active_tab_trigger:after {
        bottom: -15px;
    }

    .ad_box .box_no {
        position: absolute;
        top: 7px;
        left: -12px;
    }

    .tabs_content {
        margin-top: 26px;
    }


    .add_content {
        padding: 18px 27px;
    }

    .add_content * {
        text-align: center;
        padding: 0;
    }

    .add_content h2 {
        margin-bottom: 6px;
    }

    .add_buttons_img {
        background: none;
        justify-content: center;
    }

    .add_buttons_img ul li {
        padding-top: 3px;
        margin-bottom: 13px;
    }

    .apple_app_btn_li {
        display: none;
    }

    .add_b_c {
        padding-bottom: 0;
    }

    .social_contact_bar .scb:nth-child(1) a img {
        max-width: 145px;
    }

    .social_contact_bar .scb:nth-child(2) {
        padding-left: 17px;
        margin-left: 17px;
    }

    .social_contact_bar .scb:nth-child(2) a img {
        max-width: 145px;
    }

    .social_contact_bar .scb:nth-child(3) {
        margin-top: 26px;
    }

    .footer_p_logos {
        column-gap: 33px;
    }

    .footer_p_logos img {
        max-width: 28%;
    }

    .sep_span {
        padding-left: 3px;
        padding-right: 3px;
    }

    .sep_span:last-of-type {
        display: block;
        font-size: 0;
        width: 100%;
        height: 11px;
    }

    .footer_bottom {
        padding: 35px 0;
    }

    .footer_bottom p a {
        text-decoration: underline;
    }

    .app_btns li a {
        padding: 14px 4px !important;
        min-width: 175px !important;
        text-align: center !important;
        display: flex !important;
        justify-content: center !important;
    }

}

@media (min-width: 430px) and (max-width: 767px) {
    .social_contact_bar .scb:nth-child(2) a img {
        max-width: 185px;
    }
}

@media (max-width: 389px) {
    .web_header_btns ul {
        transform: scale(0.85);
        transform-origin: center right;
    }

    .app_btns li a {
        min-width: 155px !important;
    }

}

.Login_btn {
    font-size: larger !important;
    padding: 0px !important;
}

.blinkAnimation {
    animation: blinkMe 1s linear infinite;
}
@keyframes blinkMe {
    0% {
        color: brown;
    }
    50% {
        color: #F79001;
    }
    100% {
        color: #F9DE15;
    }
}


.banner_phone_img>img{
    height: 100% !important;
    width: 100% !important;
}
.officialwebsite{
    margin-bottom: 10px;


}
.officialwebsite>h4{
    font-size: 24px !important;
    font-weight: bolder !important;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    line-height: 28px !important;
    color: white;
}

.officialwebsite>a{
    font-size: 22px !important;
}
